import { buttonVariants } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { cn } from '@/libs/utils';
import debug from 'debug';
import { BookOpen, Home, Plus } from 'lucide-react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Constants } from '../../Constants';
import { Simone } from './Simone';

const log = debug(Constants.debug.root).extend('main');

export const AppBottomMenu: React.FC = () => {
    const location = useLocation();
    const [isSimoneVisible, setIsSimoneVisible] = React.useState(false);
    // const [createStoryAPI, { isLoading }] = useCreateStoryMutation();
    const [story, setStory] = React.useState({ id: '' });

    // Create a story when a user clicks on the Simone button.
    const onOpenChange = async () => {
        if (isSimoneVisible) {
            setIsSimoneVisible(false);
            return;
        } else {
            // createStoryAPI(null)
            //     .unwrap()
            //     .then((data) => {
            //         setStory(data);
            //         setIsSimoneVisible(true);
            //     })
            //     .catch((error) => {
            //         console.error('error: ', error);
            //     });
            setStory({ id: '2e861ed3-e3d3-477b-b2ba-aa984da4e92d' });
            setIsSimoneVisible(true);
            log(story.id);
        }
    };
    const closeSimone = () => {
        log('closeSimone');
        setIsSimoneVisible(false);
    };
    return (
        <>
            <Link
                to="/"
                className={cn(
                    'h-20',
                    'flex',
                    'flex-col',
                    'gap-1',
                    'flex-grow',
                    'items-center',
                    'justify-center',
                    location.pathname === '/' ? 'selected' : 'unselected',
                )}
            >
                <Home className="w-full" />
                <div>Home</div>
            </Link>
            <Sheet open={isSimoneVisible} onOpenChange={onOpenChange}>
                <SheetTrigger>
                    <div
                        id="simone-button"
                        className={cn(
                            buttonVariants({ variant: 'default' }),
                            'h-14',
                            'w-14',
                            'rounded-full',
                            // isLoading ? 'loading' : '',
                        )}
                    >
                        <div className="flex flex-col gap-1">
                            <Plus className="w-full" />
                        </div>
                    </div>
                </SheetTrigger>
                <SheetContent className="w-full">
                    <SheetHeader>
                        <SheetTitle>Create a new story.</SheetTitle>
                        <SheetDescription className="sr-only">Create a new story with Simone.</SheetDescription>
                    </SheetHeader>
                    <Simone storyId={story.id} close={closeSimone} />
                </SheetContent>
            </Sheet>
            <Link
                to="/stories"
                className={cn(
                    'h-20',
                    'flex',
                    'flex-col',
                    'gap-1',
                    'flex-grow',
                    'items-center',
                    'justify-center',
                    location.pathname === '/stories' ? 'selected' : 'unselected',
                )}
            >
                <BookOpen className="w-full" />
                <div className="text-sm">Stories</div>
            </Link>
        </>
    );
};
