import React from 'react';
import { AppBottomMenu } from './AppBottomMenu';
import { AppHeader } from './AppHeader';
import { ErrorList } from './ErrorList';

interface AppViewProps {
    title?: string | React.ReactNode;
    actions?: {
        items: React.ReactNode[];
        replaceExisting?: boolean;
    };
    hideGlobalMenu?: boolean;
    hideBackButton?: boolean;
    fullSizeContent?: boolean;
    children?: React.ReactNode;
    fullScreen?: boolean;
    hideHeader?: boolean;
    hideBottomMenu?: boolean;
    bottomMenuContent?: React.ReactNode;
}

export const AppView: React.FC<AppViewProps> = (props) => {
    const {
        title,
        actions,
        children,
        fullScreen,
        hideHeader,
        hideBottomMenu,
        hideBackButton,
        hideGlobalMenu,
        bottomMenuContent,
    } = props;

    return (
        <div className="flex flex-col h-full">
            {!fullScreen && !hideHeader && (
                <AppHeader
                    title={title ?? 'Hey Simone'}
                    actions={actions}
                    hideBackButton={hideBackButton}
                    hideGlobalMenu={hideGlobalMenu}
                />
            )}
            <div className="container grow overflow-y-auto">{children}</div>
            {!fullScreen && !hideBottomMenu && (
                <div id="bottom-menu" className="flex flex-row items-center">
                    {bottomMenuContent ? bottomMenuContent : <AppBottomMenu />}
                </div>
            )}
            <ErrorList />
        </div>
    );
};
