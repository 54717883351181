import { Story } from '@/models/Story';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppView } from '../components/App/AppView';
import { Loading } from '../components/App/Loading';
import { useSiteUtility } from '../libs/useSiteUtility';
import { useGetStoriesQuery } from '../services/simone';

export const Stories: React.FC = () => {
    const { data: stories, error: storiesError, isLoading: isLoadingStories } = useGetStoriesQuery();

    const siteUtility = useSiteUtility();
    siteUtility.setDocumentTitle('Dashboard');

    if (storiesError) {
        const errorMessage = JSON.stringify(storiesError);
        return (
            <AppView title="Stories" actions={{ items: [], replaceExisting: true }}>
                <div className="text-red-500">{errorMessage}</div>
            </AppView>
        );
    }

    if (isLoadingStories) {
        return (
            <AppView title="Stories" actions={{ items: [], replaceExisting: true }}>
                <Loading />
            </AppView>
        );
    }

    return (
        <AppView title="Stories" hideGlobalMenu hideBackButton>
            <div className="flex flex-col gap-6">
                {stories?.map((story: Story) => (
                    <Link
                        key={story.id}
                        to={`/story-detail/${story.id}`}
                        className="flex bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl p-4 gap-3"
                    >
                        <div className="flex-auto">
                            <p className="font-semibold font-serif text-xl pb-2">{story.title || 'preparing'}</p>
                            <p>{story.blurb}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </AppView>
    );
};
