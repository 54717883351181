import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './components/App/ProtectedRoute';
import { AuthProvider } from './libs/AuthProvider';
import { Dashboard } from './routes/Dashboard';
import { ErrorPage } from './routes/ErrorPage';
import { Login } from './routes/Login';
import { Settings } from './routes/Settings';
import { Stories } from './routes/Stories';
import { Story } from './routes/Story';
import { StoryDetail } from './routes/StoryDetail';

import { ErrorBoundary } from './components/App/ErrorBoundary';
import './index.css';

function App() {
    return (
        <AuthProvider>
            <ErrorBoundary>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/settings"
                        element={
                            <ProtectedRoute>
                                <Settings />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/stories"
                        element={
                            <ProtectedRoute>
                                <Stories />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/story-detail/:storyId"
                        element={
                            <ProtectedRoute>
                                <StoryDetail />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/story/:storyId"
                        element={
                            <ProtectedRoute>
                                <Story />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </ErrorBoundary>
        </AuthProvider>
    );
}

export default App;
