import { Button } from '@/components/ui/button';
import React from 'react';
import { AppMenu } from '../components/App/AppMenu';
import { AppView } from '../components/App/AppView';
import { useSiteUtility } from '../libs/useSiteUtility';

export const Dashboard: React.FC = () => {
    const siteUtility = useSiteUtility();
    siteUtility.setDocumentTitle();

    const appMenuAction = <AppMenu />;

    return (
        <AppView title="Home" actions={{ items: [appMenuAction], replaceExisting: true }}>
            <div className="inter flex flex-col gap-6">
                <div className="text-center ">
                    <div className="text-center">WELCOME TO HEY SIMONE</div>
                    <div>
                        Hello everyone! My name is Melissa, and I’m the CEO and founder of Hey Simone. I am so grateful
                        for your presence, courage, and curiosity as explore your desires with my app. Please don’t
                        hesitate to share your ideas and feedback with me so we can make Hey Simone a safe and sensual
                        place for everyone.
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <h2 className="font-semibold">Tips & Tricks</h2>
                    <Button variant="outline" className="h-20 text-wrap">
                        Add the Hey Simone app to your phone&apos;s home screen for easier access.
                    </Button>
                </div>
                <div className="flex flex-col gap-1">
                    <div className="font-semibold">Send Feedback</div>
                    <Button variant="outline">Tell us why you love Hey Simone</Button>
                    <Button variant="outline">Share your ideas for new features</Button>
                    <Button variant="outline">Report a bug or other issue.</Button>
                </div>
            </div>
        </AppView>
    );
};
