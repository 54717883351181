import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/libs/utils';
// import debug from 'debug';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppView } from '../components/App/AppView';
import { Loading } from '../components/App/Loading';
// import { Constants } from '../Constants';
import { useSiteUtility } from '../libs/useSiteUtility';
import { useGetStoryQuery } from '../services/simone';
// const log = debug(Constants.debug.root).extend('simone');

const CoverImage: React.FC = () => {
    return (
        <div className="flex-none">
            <img className="w-full h-64 object-cover" src="https://via.placeholder.com/150" alt="Melissa" />
        </div>
    );
};

export const StoryDetail = () => {
    const { storyId } = useParams();
    const siteUtility = useSiteUtility();
    siteUtility.setDocumentTitle('Story detail');

    const { data: story, error: storyError, isLoading } = useGetStoryQuery(storyId ?? '', { skip: !storyId });

    if (!storyId) {
        return (
            <AppView title="Stories" hideBottomMenu hideGlobalMenu>
                <p>No story ID provided.</p>
            </AppView>
        );
    }

    if (storyError) {
        const errorMessage = JSON.stringify(storyError);
        // throw new Error(`Error loading: ${errorMessage}`);
        return (
            <AppView title="Stories" hideBottomMenu hideGlobalMenu>
                <div className="text-red-500">{errorMessage}</div>
            </AppView>
        );
    }

    if (isLoading || !story) {
        return (
            <AppView title="Stories" hideBottomMenu hideGlobalMenu>
                <Loading />
            </AppView>
        );
    }

    const bottomMenuContent = (
        <Link to={`/story/${story.id}`} className={cn(buttonVariants({ variant: 'default' }), 'w-full', 'mx-4')}>
            Read
        </Link>
    );

    const snake_case_to_title_case = (str: string) => {
        return str
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <AppView hideGlobalMenu title={story.title} bottomMenuContent={bottomMenuContent}>
            <CoverImage />
            <h1 className="font-serif text-3xl italic text-center">{story.title}</h1>
            <p>{story.blurb || 'Creating your story.'}</p>

            <Accordion type="single" collapsible className="w-full">
                {Object.entries(story.preferences).map(([section, details]) => (
                    <AccordionItem key={section} value={`item-${section}`}>
                        <AccordionTrigger>{section}</AccordionTrigger>
                        <AccordionContent>
                            {Object.entries(details as { [key: string]: any }).map(([key, value]) => (
                                <div key={key}>
                                    <strong>{snake_case_to_title_case(key)}</strong>: {value}
                                </div>
                            ))}
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion>
        </AppView>
    );
};
