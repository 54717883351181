import { Alert, AlertDescription } from '@/components/ui/alert';
import { zodResolver } from '@hookform/resolvers/zod';
import Debug from 'debug';
import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Constants } from '../Constants';
import { useSiteUtility } from '../libs/useSiteUtility';

import { AppView } from '@/components/App/AppView';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useAuth } from '../libs/AuthProvider';
import { Login as LoginModel } from '../models/Login';
import { useLoginMutation } from '../services/simone/user';

const log = Debug(Constants.debug.root).extend('login');

const formSchema = z.object({
    username: z.string().min(2, {
        message: 'Username must be at least 2 characters.',
    }),
    password: z.string().min(4, {
        message: 'Password must be at least 8 characters.',
    }),
});

interface ErrorResponse {
    status: number;
    data: {
        detail: string;
    };
}

export const Login: React.FC = () => {
    const siteUtility = useSiteUtility();
    const [errorMessage, setErrorMessage] = React.useState<string>();

    const setUserInAuthContextAndRedirect = useAuth()?.setUserAndRedirect;

    siteUtility.setDocumentTitle('Login');

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: '',
            password: '',
        },
    });

    const [login] = useLoginMutation();

    const handleSignIn = (values: LoginModel) => {
        void (async () => {
            try {
                const { user, access_token } = await login(values).unwrap();
                if (!user) {
                    throw new Error('No user returned from login request');
                }

                // Instead of also doing it here. This function puts it in
                // localstorage and AuthContext.
                setUserInAuthContextAndRedirect?.(user, access_token);
            } catch (error) {
                log('login error', error);
                const err = error as ErrorResponse;
                if (err.data.detail) {
                    setErrorMessage(err.data.detail);
                } else {
                    setErrorMessage('An error occurred while trying to login: ' + error);
                }
            }
        })();
    };

    const version = import.meta.env.VITE_HEYSIMONE_COMMIT_SHA
        ? import.meta.env.VITE_HEYSIMONE_COMMIT_SHA.substring(0, 7)
        : 'development';

    return (
        <AppView fullScreen>
            <h1 className="p-4 font-serif text-4xl text-center">Welcome Back</h1>
            <p>Login to your account</p>
            {errorMessage && (
                <Alert variant="destructive">
                    <AlertDescription>{errorMessage}</AlertDescription>
                </Alert>
            )}
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSignIn)} className="space-y-8">
                    <FormField
                        control={form.control}
                        name="username"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Username</FormLabel>
                                <FormControl>
                                    <Input className="roudned-full" autoComplete="username" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Password</FormLabel>
                                <FormControl>
                                    <Input type="password" autoComplete="current-password" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit">Continue</Button>
                </form>
            </Form>
            <p className="pt-10 text-center text-gray-500 text-opacity-20 text-sm font-light italic">
                {import.meta.env.VITE_HEYSIMONE_DAEMON_URL}
            </p>
            <p className="text-center text-gray-500 text-opacity-20 text-sm font-light italic">{version}</p>
        </AppView>
    );
};
