import { buttonVariants } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { Story } from '../../../models/Story';
interface FinishedProps {
    close: () => void;
    story: Story;
}

export const Finished = (props: FinishedProps) => {
    const { close } = props;

    return (
        <div className="flex flex-col content-center gap-2">
            <h1 className="grow p-3 font-serif text-4xl italic">
                <em>
                    Mmm<em>, here we go...</em>
                </em>
            </h1>
            <p>
                For the next few minutes I’ll be crafting your full story. When I&apos;m done, I&apos;ll leave it on
                your Story Shelf to enjoy.
            </p>
            <Link to="/stories" className={buttonVariants({ variant: 'default' })} onClick={() => close()}>
                Story Shelf
            </Link>
            <Link to="/" className={buttonVariants({ variant: 'outline' })} onClick={() => close()}>
                Home
            </Link>
        </div>
    );
};
