import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import React from 'react';
import { AppMenu } from './AppMenu';

interface AppHeaderProps {
    title: string | React.ReactNode;
    hideGlobalMenu?: boolean;
    hideBackButton?: boolean;
    actions?: {
        items: React.ReactNode[];
        replaceExisting?: boolean;
    };
}

export const AppHeader: React.FC<AppHeaderProps> = (props) => {
    const { title, actions, hideGlobalMenu, hideBackButton } = props;

    const actionItems = [];

    // Custom actions from the caller
    if (actions && actions?.items.length > 0) {
        actionItems.push(...actions.items.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>));
    }

    // Default navigation and other global actions
    if (!actions?.replaceExisting || !actions) {
        // Back button
        if (!hideBackButton && window.history.length > 1) {
            actionItems.push(
                <Button key="back" variant="ghost" size="icon" onClick={() => window.history.back()}>
                    <ArrowLeft />
                </Button>,
            );
        }

        // Global menu
        if (!hideGlobalMenu) {
            actionItems.push(<AppMenu key="menu" />);
        }
    }

    const backButton = actionItems.find((item) => item.key === 'back');

    return (
        <div>
            <div className="flex flex-row w-screen justify-center justify-items-center">
                {backButton && <div className="pt-3">{backButton}</div>}
                {title && typeof title === 'string' ? (
                    <h1 className="grow p-3 font-serif text-4xl italic">{title}</h1>
                ) : (
                    title
                )}
                <div className="p-3 flex flex-row flex-nowrap">{actionItems.filter((item) => item.key !== 'back')}</div>
            </div>
        </div>
    );
};
